import {Injectable} from '@angular/core';
import {LocalStorageManager} from '../utils/local-storage-manager/local-storage-manager.utils';
import moment from 'moment';
import {PeriodData, PeriodType} from '../components/common-components/selector-period/interfaces/period.interface';
import {CalendarModel} from '../models/calendar.model';
import {CardControl} from '../components/cards/interfaces/CardsControl.interface';
import {CardsService} from '../components/cards/services/cards.service';
import {PeriodModel} from '../components/common-components/selector-period/Model/periodSelector.Utils';
import {ApiService} from './api.service';
import {Response} from '../interfaces/responses-general.interface';
import {SharedData, SyncHome} from '../interfaces/sync.interface';
import {UserCardModel} from '../models/general/user.card.model';
import {CARDS_OBJECT} from '../interfaces/constants/cards-list.constant';
import {UserCard} from '../interfaces/general/user.card.interface';
import {AccountModel} from '../models/general/account.model';
import {Account} from '../interfaces/general/account.interface';
import {CategoryModel} from '../models/general/category.model';
import {MovementModel} from '../models/general/movement.model';
import {Category} from '../interfaces/general/category.interface';
import {Movement} from '../interfaces/general/movement.interface';
import {SubcategoryModel} from '../models/general/subcategory.model';
import {Subcategory} from '../interfaces/general/subcategory.interface';
import {Debt} from '../interfaces/general/debt.interface';
import {DebtModel} from '../models/general/debt.model';
import {DebtRecord} from '../interfaces/general/debt.record.interface';
import {DebtRecordModel} from '../models/general/debt.record.model';
import {DebtsService} from '../pages/debts/services/debts.service';
import {Budget} from '../interfaces/general/budget.interface';
import {BudgetModel} from '../models/general/budget.model';
import {Card} from '../interfaces/general/card.interface';

@Injectable({
  providedIn: 'root'
})
export class SyncAll {

  currentDate: moment.Moment = LocalStorageManager.home.getDate();
  currentPeriod: PeriodData;
  calendar = new CalendarModel(this.currentDate);

  cards: CardControl[] = [];

  private userCardModel: UserCardModel[] = [];
  private accountModel: AccountModel[] = [];
  private categoryModel: CategoryModel[] = [];
  private subcategoryModel: SubcategoryModel[] = [];
  private movementModel: MovementModel[] = [];
  private debtModel: DebtModel[] = [];
  private debtRecordModel: DebtRecordModel[] = [];
  private budgetModel: BudgetModel[] = [];

  public showLoadingChange: boolean = false;

  constructor(
    private cardsServices: CardsService,
    private debtServices: DebtsService,
    private api: ApiService
  ) {
    let periods = new PeriodModel().periods;
    this.currentPeriod = periods.find(p => p.period == LocalStorageManager.home.getPeriod()! ?? PeriodType.monthly)!;
  }

  public getUserCards(): UserCardModel[] {
    return [...this.userCardModel];
  }

  public getAccounts(): AccountModel[] {
    return [...this.accountModel];
  }

  public getMovementModel(): MovementModel[] {
    return [...this.movementModel];
  }

  public getDebtModel(): DebtModel[] {
    return [...this.debtModel];
  }

  public getBudgetModel(): BudgetModel[] {
    return [...this.budgetModel]
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public getAll() {
    const params = {
      'pk_user': this.api.userData?.user_id,
      'pk_subscription': this.api.userData?.subscription_id,
      'date_last_sync': '',
      'request_sync': 0
    };
    return new Promise((resolve, reject) => {
      this.api.post('json_sync', params).subscribe((response: Response<SyncHome>) => {
        if (response.status == 1) {
          const data = response.data;
          this.setUserCard(data.user_data.user_cards);
          this.setAccountModel(data.subscription_data.table_accounts);
          this.setCategoryModel(data.subscription_data.table_categories);
          this.setSubcategoryModel(data.category_data.table_subcategories);
          this.setMovementModel(data.account_data.table_movements);
          this.setDebtRecordsModel(data.fk_data.table_debts_records);
          this.setDebtModel(data.account_data.table_debts);
          this.setBudgetModel(data.subscription_data.table_budgets);
          this.setSharedModel(data.shared_data);
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }

  public updateCards(cards: CardControl[]): Promise<Card[]> {
    return this.cardsServices.updateCards(cards) as Promise<Card[]>;
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private setUserCard(userCards: UserCard[]) {
    this.userCardModel = [];
    for (const card of userCards) {
      this.userCardModel.push(new UserCardModel(card, CARDS_OBJECT[card.fk_card]));
    }
    this.userCardModel = this.userCardModel.sort((a, b) => a.orderCard - b.orderCard);
  }

  private setSharedModel(data: SharedData) {
    this.setAccountModel(data.table_accounts, true, false);
    this.setCategoryModel(data.table_categories, true, false);
    this.setMovementModel(data.table_movements, true, false);
  }

  private setAccountModel(accounts: Account[], isShared: boolean = false, reset: boolean = true) {
    if (!accounts) {
      return
    }
    if (reset) {
      this.accountModel = [];
    }
    for (const account of accounts) {
      const model = new AccountModel(account);
      model.isShared = isShared;
      this.accountModel.push(model);
    }
    this.accountModel = this.accountModel.filter(row => row.shown == 1 && row.deleted == 0);
  }

  private setCategoryModel(categories: Category[], isShared: boolean = false, reset: boolean = true) {
    if (!categories) {
      return
    }
    if (reset) {
      this.categoryModel = [];
    }
    for (const category of categories) {
      const model = new CategoryModel(category);
      model.isShared = isShared;
      this.categoryModel.push(model);
    }
    this.categoryModel = this.categoryModel.filter(row => row.shown == 1 && row.deleted == 0);
  }

  private setSubcategoryModel(subcategories: Subcategory[]) {
    this.subcategoryModel = [];
    for (const subcategory of subcategories) {
      this.subcategoryModel.push(new SubcategoryModel(subcategory));
    }
  }

  private setMovementModel(movements: Movement[], isShared: boolean = false, reset: boolean = true) {
    if (!movements) {
      return
    }
    if (reset) {
      this.movementModel = [];
    }
    for (const movement of movements) {
      const model = this.getMovementModelRow(movement);
      if (model) {
        model.isShared = isShared;
        this.movementModel.push(model);
      }
    }
    this.showLoadingChange = (this.movementModel.length >= 3000);
  }

  private getMovementModelRow(movement: Movement): MovementModel | null {
    const account = this.accountModel.find(row => row.pkAccount === movement.fk_account && row.deleted === 0);
    const category = this.categoryModel.find(row => row.pkCategory === movement.fk_category && row.deleted === 0);
    const subcategory = this.subcategoryModel.find(row => row.pkSubcategory === movement.fk_subcategory && row.deleted === 0);
    if (!!movement.fk_category && !category) { return null }
    if (!!movement.fk_subcategory && !subcategory) { return null }
    if (account) {
      return new MovementModel(movement, account, category, subcategory);
    }
    return null;
  }

  private setBudgetModel(budgets: Budget[]) {
    this.budgetModel = [];
    for (const budget of budgets) {
      const model = new BudgetModel(budget);
      this.setRelationsBudget(model);
      this.budgetModel.push(model);
    }
  }

  private setRelationsBudget(model: BudgetModel) {
    model.account = this.accountModel.find(row => row.pkAccount == model.fkAccount) ?? null;
    model.category = this.categoryModel.find(row => row.pkCategory == model.fkCategory) ?? null;
    model.subcategory = this.subcategoryModel.find(row => row.pkSubcategory == model.fkSubcategory) ?? null;
  }

  private setDebtModel(debts: Debt[]) {
    this.debtModel = [];
    for (const debt of debts) {
      const model = new DebtModel(debt);
      this.setRelationsDebt(model);
      this.debtModel.push(model);
    }
  }

  private setRelationsDebt(model: DebtModel) {
    const account = this.accountModel.find(({pkAccount}) => pkAccount == model.fkAccount);
    model.debtRecords = this.debtRecordModel.filter(({fkDebt}) => fkDebt == model.pkDebt);
    if (account) {
      model.account = account;
    }
    this.debtServices.setTotalDebt(model);
  }

  private setDebtRecordsModel(debtRecords: DebtRecord[]) {
    this.debtRecordModel = [];
    for (const record of debtRecords) {
      this.debtRecordModel.push(new DebtRecordModel(record));
    }
  }
}
