import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {AccountModel} from '../../../models/general/account.model';
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-accounts-selector',
  templateUrl: './accounts-selector.component.html',
  styleUrls: ['./accounts-selector.component.css']
})
export class AccountsSelectorComponent implements OnInit, OnChanges {
  @Input() accounts: AccountModel[] = [];
  @Input() currentAccount?: AccountModel | null = null;
  @Output() accountSelected = new EventEmitter<AccountModel>();
  isDarkMode = LocalStorageManager.isDarkmode;
  menuColor: string = 'white';
  textColor: string = 'white';

  ngOnInit() {
    this.setStyleList();
  }

  ngOnChanges(): void {
    this.selectAccountInitial();
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public selectAccount(model: AccountModel) {
    this.accounts.forEach(item => item.selected = 0);
    model.selected = 1;
    this.currentAccount = model;
    this.accountSelected.emit(model);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private selectAccountInitial() {
    if (this.currentAccount) { return }
    this.currentAccount = this.accounts.find(row => row.selected === 1);
    if (!this.currentAccount) {
      this.currentAccount = this.accounts[0];
      this.currentAccount.selected = 1;
    }
  }

  private setStyleList() {
    this.menuColor = this.isDarkMode? '#575F68' : 'white';
    this.textColor = this.isDarkMode? 'white' : 'black';
  }
}
