import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CategoryModel} from '../../../models/general/category.model';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';
import {SubcategoryModel} from '../../../models/general/subcategory.model';
import {DataSelectorCategories} from '../../../interfaces/general/utils.interface';

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.css']
})
export class CategorySelectorComponent implements OnInit, OnChanges {
  @Input() showSubcategory: boolean = true;
  @Input() sizeWidth: string = '45%';
  @Input() showShared: boolean = false;
  @Input() categories: CategoryModel[] = [];
  @Input() sign: string | null = null;
  @Input() currentCategory: CategoryModel | null = null;
  @Input() currentSubcategory: SubcategoryModel | null = null;
  @Output() emitDataSelected = new EventEmitter<DataSelectorCategories>();

  categoriesSign: CategoryModel[] = [];
  buttonColor: string = 'transparent';
  menuColor: string = 'white';
  textColor: string = 'white';
  iconName: string = 'icon_empty';
  nameSelected: string | null = null;
  isDarkMode = LocalStorageManager.isDarkmode;

  constructor(
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setStyleList();
  }

  ngOnChanges(): void {
    this.changesValues();
    this.filterBySign();
  }

  public showListSubcategory(model: CategoryModel) {
    model.expandedList = !model.expandedList;
  }

  public setCategory(model: CategoryModel) {
    this.currentCategory = model;
    this.currentSubcategory = null;
    this.setValueColors();
  }

  public selectSubcategory(model: CategoryModel, subcategory: SubcategoryModel) {
    this.currentCategory = model;
    this.currentSubcategory = subcategory;
    this.setValueColors();
  }

  //MARK: PRIVATE METHODS ------------------------------------------------------------
  private  setStyleList() {
    this.buttonColor = this.isDarkMode ? '#575F68' : 'transparent';
    this.menuColor = this.isDarkMode ? '#575F68' : 'white';
    this.textColor = this.isDarkMode ? 'white' : '#212429';
    this.iconName = 'icon_empty';
    this.nameSelected = null;
  }

  private filterBySign() {
    if (!this.sign) { return }
    if ( this.sign == '-' || this.sign == '+') {
      this.categoriesSign = this.categories.filter(row => row.sign == this.sign);
    }
  }

  private emitData() {
    this.emitDataSelected.emit({
      category: this.currentCategory,
      subcategory: this.currentSubcategory,
    });
  }

  private changesValues() {
    this.setListCategories();
    if(this.currentCategory) {
      this.setValueColors();
    } else {
      this.setStyleList();
    }
  }

  private setListCategories() {
    if(this.categoriesSign.length == 0) {
      this.categoriesSign = [...this.categories];
    }
    this.categoriesSign = this.categories.filter(row => row.isShared == this.showShared)
  }

  private setValueColors() {
    this.nameSelected = `${this.currentCategory!.name}`
    if(this.currentSubcategory) {
      this.iconName = this.currentSubcategory.iconName;
      this.nameSelected = `${this.nameSelected} - ${this.currentSubcategory.name}`;
    } else {
      this.iconName = this.currentCategory!.iconName
    }
    this.buttonColor = `#${this.currentCategory!.colorHex}`
    this.emitData();
  }
}
