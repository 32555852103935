import {Component, OnInit} from '@angular/core';
import moment from 'moment';
import {CalendarModel} from 'src/app/models/calendar.model';
import {PreferencesModel} from 'src/app/models/preferences.model';
import {CardControl, CardType} from '../../../components/cards/interfaces/CardsControl.interface';
import {PeriodData, PeriodType} from '../../../components/common-components/selector-period/interfaces/period.interface';
import {PeriodModel} from '../../../components/common-components/selector-period/Model/periodSelector.Utils';
import {LocalStorageManager} from '../../../utils/local-storage-manager/local-storage-manager.utils';
import {FortnightModel} from '../../../models/biweekly.model';
import {WeekModel} from '../../../models/week.model';
import {TranslateService} from '@ngx-translate/core';
import {SyncAll} from '../../../services/sync-all.service';
import {CalendarOptions} from 'src/app/interfaces/calendar-options.interface';
import {PickerType} from 'src/app/components/common-components/selector-date-by-periods/selector-date-by-periods.component';
import {UserCardModel} from '../../../models/general/user.card.model';
import {AccountModel} from '../../../models/general/account.model';
import {FilterService} from '../../../services/filter.service';
import {RangeDateModel} from '../../../models/general/range.date.model';
import {MovementModel} from '../../../models/general/movement.model';
import {Card} from '../../../interfaces/general/card.interface';

@Component({
  selector: 'app-home',
  templateUrl: './Home.component.html',
  styleUrls: ['./Home.component.css']
})
export class HomeComponent implements OnInit {
  movements: MovementModel[] = [];
  accounts: AccountModel[] = [];
  selectedWeek: WeekModel;
  selectedFortnight: FortnightModel;
  showCards: boolean = true;
  noData: boolean = false;

  cards: CardControl[] = [];
  cardsTypes = CardType;
  userCards: UserCardModel[] = [];

  isDarkmode = LocalStorageManager.isDarkmode;
  preferences = PreferencesModel.shared;

  date: moment.Moment = LocalStorageManager.home.getDate();
  dateRanges: RangeDateModel | null = null;
  secondDate: moment.Moment = LocalStorageManager.home.getDate2();

  calendarOptions!: CalendarOptions;
  options2?: CalendarOptions;

  isDragEnabled = false;

  periods = new PeriodModel().periods;
  period = PeriodType;
  selectedPeriod: PeriodData;
  calendar: CalendarModel;

  loading: boolean = false;
  notHaveData: boolean = false;
  loadingChange: boolean = false;
  message: string | null = null;
  afterRangeDates: RangeDateModel | null = null;

  selectedAccounts: AccountModel[] = [];
  totalAccountBalance: number = 0;
  keyMessage: string = '';
  lang = LocalStorageManager.lang;
  typeDate = PickerType.YEAR;

  constructor(
    private translate: TranslateService,
    private services: SyncAll,
    private filterService: FilterService,
  ) {
    this.translate.use(this.lang!);
    this.selectedPeriod = this.services.currentPeriod;
    this.services.calendar = new CalendarModel(this.date);
    this.calendar = this.services.calendar;
    this.selectedWeek = this.services.calendar.currentWeek;
    this.selectedFortnight = this.services.calendar.currentFortnight;
    this.setPeriod(this.selectedPeriod.period);
  }

  ngOnInit() {
    this.loadData();
  }

  //MARK: PUBLIC METHODS -----------------------------------------------------------------------------
  public getData() {
    this.isDragEnabled = false;
    this.accounts = this.services.getAccounts();
    this.filterService.budgets = this.services.getBudgetModel();
    this.filterService.setDebts(this.services.getDebtModel());
    setTimeout(() => {
      this.userCards = this.services.getUserCards();
      this.selectedAccounts = this.accounts;
      this.setFilters().then();
    }, 1);
  }

  public changePeriod(period: PeriodType) {
    this.setPeriod(period);
    this.setFilters().then();
    /*this.getData();*/
  }

  public setPeriod(period: PeriodType) {
    LocalStorageManager.home.setPeriod(period);
    let periodModel = this.periods.find(p => p.period == period)!;
    this.selectedPeriod = periodModel;
    this.services.currentPeriod = periodModel;
    switch (period) {
      case PeriodType.daily:
        this.typeDate = PickerType.DATE;
        this.calendarOptions = {format: 'DD-MMMM-YYYY', locale: this.lang!, maxDate: false};
        break;
      case PeriodType.weekly:
      case PeriodType.fortnightly:
      case PeriodType.annually:
        this.typeDate = PickerType.YEAR;
        this.calendarOptions = {format: 'YYYY', locale: this.lang!, maxDate: false};
        break;
      case PeriodType.monthly:
        this.typeDate = PickerType.MONTH;
        this.calendarOptions = {format: 'MMMM-YYYY', locale: this.lang!, maxDate: false};
        break;
      case PeriodType.byDates:
        this.typeDate = PickerType.DATE;
        this.calendarOptions = {format: 'DD-MMMM-YYYY', locale: this.lang!, maxDate: this.secondDate};
        this.options2 = {format: 'DD-MMMM-YYYY', locale: this.lang!, minDate: this.date};
        break;
    }
  }

  public changeDate(date: moment.Moment) {
    this.date = date;
    this.setFilters().then();
  }

  public changeRangeDate(range: RangeDateModel) {
    this.dateRanges = range;
    this.setFilters().then();
  }

  public setAfterRangeDates(range: RangeDateModel) {
    this.afterRangeDates = range;
  }

  public setSelectedAccounts(accounts: AccountModel[]) {
    this.selectedAccounts = accounts;
    this.setFilters().then();
  }

  public updateCards() {
    let list = document.getElementById('list')!.getElementsByTagName('li');
    let cardsUpdated: CardControl[] = [];
    let cardButton = this.cards.find(c => c.type == CardType.buttons);
    let cardIndexIncrement: boolean = false;
    for (let i = 0; i < list!.length; i++) {
      const model = this.userCards.find(row => row.card.id == list[i].value)!;
      if (cardButton?.order == i) {
        cardIndexIncrement = true;
      }
      cardsUpdated.push({
        ...model.card,
        id: model.pkUserCard,
        order: cardIndexIncrement ? i + 1 : i,
        hidden: (model.shown === 0)
      });
    }
    this.sendUpdateCards(cardsUpdated)
  }

  public toggleDragDropMode() {
    this.isDragEnabled = !this.isDragEnabled;
  }

  public toggleCardHidden(card: CardControl) {
    card.hidden = !card.hidden;
  }

  public setDate(date: moment.Moment) {
    this.date = date;
    LocalStorageManager.home.setDate(date);
    this.services.currentDate = date;
    this.loadingChange = this.services.showLoadingChange;
    setTimeout(() => {
      this.changeDate(date);
    }, 800);
  }

  //MARK: PRIVATE METHODS ----------------------------------------------------------------------------
  private loadData() {
    this.notHaveData = true;
    this.loading = true;
    if (this.services.getMovementModel().length > 0) {
      this.loading = false;
      this.notHaveData = false;
      this.loadingChange = this.services.showLoadingChange;
      setTimeout(() => {
        this.getData();
      }, 800);
    } else {
      this.services.getAll().then(_ => {
        this.loading = false;
        this.notHaveData = false;
        this.loadingChange = this.services.showLoadingChange;
        setTimeout(() => {
          this.getData();
        }, 800);
      }, _ => {
        this.notHaveData = true;
        this.loading = false;
        this.keyMessage = 'error_messages.sync';
      });
    }
  }

  private sendUpdateCards(cardsUpdate: CardControl[]) {
    this.toggleDragDropMode();
    this.services.updateCards(cardsUpdate).then((data: Card[]) => {
      this.userCards = this.userCards.map(row => {
        const cardUser = data.find(item => item.pk_user_card === row.pkUserCard);
        if (cardUser) {
          return {
            ...row,
            show: cardUser.shown,
            orderCard: cardUser.order_card
          }
        }
        return row;
      });
    });
  }

  private async setFilters(rangeDate: RangeDateModel | null = null) {
    this.showCards = false;
    this.filterService.period = this.selectedPeriod.period;
    this.filterService.currentDate = rangeDate?.dateStart ?? this.date;
    this.filterByAccount();
    await this.getCurrentMovements();
    if (this.filterService.movementsAll.length == 0) {
      this.filterService.movementsAll = this.services.getMovementModel();
    }
    this.filterService.movementsPeriod = this.movements;
    setTimeout(() => {
      this.showCards = true;
      this.loadingChange = false;
    }, 0);
  }

  private filterByAccount() {
    this.filterService.accounts = this.selectedAccounts;
    this.getTotalBalanceByAccount();
  }

  private getCurrentMovements() {
    return new Promise<void>(resolve => {
      const allMovements = this.services.getMovementModel();
      this.movements = allMovements.filter(row => {
        const inAccount = this.selectedAccounts.includes(row.account!);
        const showTransfers = this.preferences.transfersHome || row.transfer == 0;
        const inRangeDate = this.inRangeDate(row.date);
        return inAccount && showTransfers && inRangeDate;
      });
      resolve();
    })
  }

  private inRangeDate(date: string): boolean {
    switch (this.selectedPeriod.period) {
      case PeriodType.daily:
        return this.date.isSame(date, 'day');
      case PeriodType.weekly:
      case PeriodType.fortnightly:
        const {dateStart, dateEnd, dateFormatter } = this.dateRanges!;
        const dateMoment = moment(date);
        return dateMoment.isBetween(dateStart, dateEnd, undefined, '[]');
      case PeriodType.monthly:
        return this.date.isSame(date, 'month');
      default:
        return this.date.isSame(date, 'year');
    }
  }

  private getTotalBalanceByAccount() {
    this.totalAccountBalance = 0;
    this.selectedAccounts.forEach(row => {
      if (row.sign == '-') {
        this.totalAccountBalance -= row.initialBalance;
      } else {
        this.totalAccountBalance += row.initialBalance;
      }
    });
  }
}
