import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { LoadingViewComponent } from './loading-view/loading-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectorMovementTypeComponent } from './selector-movement-type/selector-movement-type.component';
import { SelectorMovementStatusComponent } from './selector-movement-status/selector-movement-status.component';
import { SelectorOrderComponent } from './selector-order/selector-order.component';
import { BottomBarBalancesComponent } from './bottom-bar-balances/bottom-bar-balances.component';
import { BottomBarAddButtonComponent } from './bottom-bar-add-button/bottom-bar-add-button.component';
import { SelectorPeriodComponent } from './selector-period/selector-period.component';
import { CustomSwitchComponent } from './custom-switch/custom-switch.component';
import { SelectorDateComponent } from './selector-date/selector-date.component';
import { SegmentedSelectorComponent } from './segmented-selector/segmented-selector.component';
import { PipesModule } from '../../pipes/pipes.module';
import { SelectorDateByPeriodsComponent } from './selector-date-by-periods/selector-date-by-periods.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotHaveDataComponent } from './not-have-data/not-have-data.component';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import { HeaderTitleComponent } from './header-title/header-title.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SelectorMultiAccountComponent } from './selector-multi-account/selector-multi-account.component';
import { SelectCheckAllComponent } from './selector-multi-account/select-check-all/select-check-all.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SelectorMultiCategoriesComponent } from './selector-multi-categories/selector-multi-categories.component';
import { SelectCheckAllCategoriesComponent } from './selector-multi-categories/select-check-all-categories/select-check-all-categories.component';
import { SelectorAccountsComponent } from './selector-accounts/selector-accounts.component';
import { SelectorCategoriesComponent } from './selector-categories/selector-categories.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CategorySelectorComponent } from './category-selector/category-selector.component';
import { SelectorDatePeriodsComponent } from './selector-date-periods/selector-date-periods.component';
import {MatCardModule} from '@angular/material/card';
import { MessageInfoAlertComponent } from './message-info-alert/message-info-alert.component';
import { LoadingChangeViewComponent } from './loading-change-view/loading-change-view.component';
import { AccountsSelectorComponent } from './accounts-selector/accounts-selector.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoadingViewComponent,
    SelectorMovementTypeComponent,
    SelectorMovementStatusComponent,
    SelectorOrderComponent,
    BottomBarBalancesComponent,
    BottomBarAddButtonComponent,
    SelectorPeriodComponent,
    CustomSwitchComponent,
    SelectorDateComponent,
    SegmentedSelectorComponent,
    SelectorDateByPeriodsComponent,
    NotHaveDataComponent,
    EmptyDataComponent,
    HeaderTitleComponent,
    SelectorMultiAccountComponent,
    SelectCheckAllComponent,
    SelectorMultiCategoriesComponent,
    SelectCheckAllCategoriesComponent,
    SelectorAccountsComponent,
    SelectorCategoriesComponent,
    CategorySelectorComponent,
    SelectorDatePeriodsComponent,
    MessageInfoAlertComponent,
    LoadingChangeViewComponent,
    AccountsSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgOptimizedImage,
    MatCardModule
  ],
    exports: [
        LoadingViewComponent,
        SelectorMovementTypeComponent,
        SelectorMovementStatusComponent,
        SelectorOrderComponent,
        BottomBarBalancesComponent,
        BottomBarAddButtonComponent,
        SelectorPeriodComponent,
        CustomSwitchComponent,
        SelectorDateComponent,
        SegmentedSelectorComponent,
        SelectorDateByPeriodsComponent,
        NotHaveDataComponent,
        EmptyDataComponent,
        HeaderTitleComponent,
        SelectorMultiAccountComponent,
        SelectorMultiCategoriesComponent,
        SelectorAccountsComponent,
        SelectorCategoriesComponent,
        CategorySelectorComponent,
        SelectorDatePeriodsComponent,
        MessageInfoAlertComponent,
        LoadingChangeViewComponent,
        AccountsSelectorComponent
    ]
})
export class CommonComponentsModule { }
