<app-maintenance *ngIf="maintenance"></app-maintenance>

<body *ngIf="!maintenance"
  class="bg-primary-light"
  [ngClass]="{'bg-primary-dark': isDarkmode}"
  >
  <app-message-download
    (emmiter)="setShow($event)"
    >
</app-message-download>
  <div class="container center-screen">
    <div
      class="card"
      [ngClass]="{'dark-border': isDarkmode, 'white-border': !isDarkmode}"
      >
      <div
        class="card-body background-card shadow pt-5 pb-5"
        [ngClass]="{'background-dark-card white-text': isDarkmode}"
        >
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <h2 class="purple-text">{{ "initial_login.title_welcome" | translate }}</h2>
              <form [formGroup]="loginForm">
                <input
                  class="p-2 mt-5 mb-3 offset-1 col-10 shadow clear-outline"
                  type="email"
                  placeholder="{{ 'password_section.email' | translate }}"
                  formControlName="email"
                  [ngClass]="{'bg-gray white-text': isDarkmode}"
                  >
                <input
                  class="p-2 offset-1 col-10 mb-3 shadow clear-outline"
                  type="password"
                  placeholder="{{ 'password_section.password' | translate }}"
                  formControlName="password"
                  [ngClass]="{'bg-gray white-text': isDarkmode}"
                  >
                <button
                  class="btn btn-secondary btn-block offset-1 col-10 mb-2 clear-outline white-text"
                  (click)="getUser()"
                  >{{ "initial_login.button_login" | translate }}
                </button>
              </form>
              <a
                data-bs-toggle="modal"
                data-bs-target="#modal-mail-password"
                class="mt-1"
                href="#"
                >{{ "password_section.password_forgot" | translate }}
              </a>
              <hr>
              <button
                (click)="GoogleAuth()"
                class="btn btn-outline-secondary btn-block offset-1 col-10 mb-2 clear-outline"
                >
                <span class="pe-3">
                  <i
                    class="fa fa-google pe-2"
                    aria-hidden="true"></i>
                </span>
                {{ "initial_login.button_google" | translate }}
              </button>
              <button
                class="btn btn-block offset-1 col-10 mb-2 clear-outline"
                [ngClass]="{'btn-outline-light': isDarkmode, 'btn-dark': !isDarkmode}"
                (click)="AppleAuth()"
                >
                <span class="pe-3">
                  <i class="fas fa-apple-alt  pe-2"></i>
                </span>
                  {{ "initial_login.button_apple" | translate }}
              </button>
            </div>
            <div class="col-12 col-md-6 text-center">
              <img
                src="assets/images/GD4-256.png"
                class="mt-5"
                width="100"
                >
              <h2 class="white mt-2">{{ "app_name" | translate }}</h2>
              <p class="white">{{ "web_messages.this_aplication_encodemx" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-3 gray">
      <p class = "gray">
        {{ "initial_login.login_initial_privacy_policy_1" | translate }}
        <a routerLink="termsandconditions" class = "gray">{{ "initial_login.login_initial_privacy_policy_2" | translate }}</a>
        {{ "initial_login.login_initial_privacy_policy_3" | translate }}
        <a routerLink="noticeofprivacy" class = "gray">{{ "initial_login.login_initial_privacy_policy_4" | translate }}</a>
      </p>
      <p>
        {{"web_messages.version" | translate}}:
        {{ NU_VERSION }}
      </p>
      <!-- <p>Si inicias sesión, aceptas los <a href="https://encodemx.com/termsandconditions/" class="gray">Terminos de uso</a> y la <a href="https://encodemx.com/noticeofprivacy/" class="gray"> Politica de privacidad</a></p> -->
      <button
        hidden
        id="open-modal-change-password"
        data-bs-toggle="modal"
        data-bs-target="#modal-recover-password"
        >
      </button>
      <button
        hidden
        id="open-modal-success"
        data-bs-toggle="modal"
        data-bs-target="#app-modal-success"
        >
      </button>
    </div>
  </div>
</body>

<app-recover-mail-password
  (onEventEmittedPkUser)="setPkUserPassword($event)"
  (onEventEmittedToken)="setTokenPassword($event)"
  (onEventEmittedCode)="setCode($event)"
  (onEventEmittedEmail)="setEmailPassword($event)"
  >
</app-recover-mail-password>

<app-recover-password
  [token] = "tokenPassword"
  [pkUserPassword] = "pkUserPassword"
  [code] = "codePassword"
  [emailPassword] = "emailPassword"
  (onEventEmitted) = "successChangePassword()"
  >
</app-recover-password>

<app-modal-message
  [title] = "titleMessage"
  [bodyText] = "bodyTextMessage"
  >
</app-modal-message>

