import { Injectable } from '@angular/core';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { CardControl } from '../interfaces/CardsControl.interface';
import {ApiService} from '../../../services/api.service';
import { Response } from '../../../interfaces/responses-general.interface';
import {Card} from '../../../interfaces/general/card.interface';
@Injectable({
  providedIn: 'root'
})
export class CardsService {

  private _preferences: PreferencesModel = PreferencesModel.shared;

  constructor(
    private api: ApiService
  ) { }

  public updateCards(cards: CardControl[]) {
    let params = {
      get : "user_cards",
      cards: this.getCardsParams(cards),
    }
    return new Promise((resolve, _) => {
      this.api.update(params).subscribe((response: Response<Card[]>) => {
        resolve(response.data);
      });
    });
  }

  public getCardsParams(cards: CardControl[]) {
    return cards.map(card => {
      return {
        pk_user_card : card.id,
        shown : card.hidden ? 0 : 1,
        order_card : card.order,
        fk_preference: this._preferences.pkPreference,
        server_date : ""
      }
    });
  }

}
